import React from 'react';
import { Col, Container, ResponsiveEmbed, Row } from 'react-bootstrap';
import { Footer, Layout, Navbar, PageHeader, Sponsor } from '@components';

const InfoRequestPage = () => <Layout className="about-page">
  <Navbar />
  <PageHeader title="SoIT Information Request Form" />

  <Container style={{ marginBottom: `1rem`, marginTop: `3rem` }}>
    <Row>
      <Col>
        <ResponsiveEmbed style={{ height: `1100px` }}>
          <iframe
            id="embed"
            title="IT Expo Information Request Form"
            src="https://webforms.uc.edu/soit-information-request-form/"
            className="embed-responsive-item"
            scrolling="no" />
        </ResponsiveEmbed>
      </Col>
    </Row>
  </Container>

  <Sponsor />
  <Footer />
</Layout>;

export default InfoRequestPage;
